import React from "react";
import "./Privacy.css";



function Privacy() {

  return (
    <>

      <div className="privacy-container container">
        <h1 className="privacy-title ">Privacy Policy</h1>
        {/* <!-- added py-5 and px-5 for vertical and horizontal padding to tabbed content --> */}
        <div className="privacy-content py-5 px-5">
          <div className="Privacy-upper" id="privacy">
            <p>{`Last Updated: 09/01/2024 : Cookie validity is 60 days, after which they are deleted.

              Thank you for visiting duchytechservices.co.uk ("we," "us," or "our"). This Privacy Policy outlines how we collect, use, and safeguard your personal information. By using our website, you agree to the terms outlined in this policy.

              1. Information We Collect:

              1.1 Personal Information:

              When you visit our website, we may collect personal information such as your name, email address, and contact details.
              1.2 Automatically Collected Information:

              We automatically collect non-personal information, including but not limited to your IP address, browser type, and device information, to enhance your user experience.
              2. How We Use Your Information:

              2.1 Personalization:

              We use your information to personalize your experience on our website, providing content and features tailored to your interests.
              2.2 Communication:

              We may use your contact information to send you relevant updates, newsletters, and promotional materials. You can opt out of these communications at any time.
              2.3 Analytics:

              We utilize analytics tools to analyze website traffic, user behavior, and demographics to improve our services and user experience.
              3. Information Sharing:

              3.1 Third-Party Services:

              We may share your information with trusted third-party service providers to assist us in delivering our services. These third parties are obligated to maintain the confidentiality of your information.
              Please note that EmailJS is used to send emails from the contact form in a secure manner, this means however that contact form data is sent to emailJS, and Outlook.com.
              Please see https://www.emailjs.com/legal/data-protection-agreement/ for EmailJS data processing information, and here https://privacy.microsoft.com/en-gb/privacystatement for Microsofts privacy details.
              3.2 Legal Compliance:

              We may disclose your information if required by law or in response to a valid legal request.
              4. Cookies:

              Our website uses cookies to enhance your browsing experience. You can control cookie preferences through your browser settings.
              5. Security:

              We employ industry-standard security measures to protect your information from unauthorized access, disclosure, alteration, and destruction.
              6. Your Rights:

              You have the right to access, update, or delete your personal information. Contact us if you wish to exercise these rights.
              7. Children's Privacy:

              Our website is not directed at children under the age of 13. We do not knowingly collect personal information from children.
              8. Changes to this Privacy Policy:

              We reserve the right to update this Privacy Policy. Check the "Last Updated" date to review the latest version.
              9. Contact Information:

              If you have any questions or concerns regarding this Privacy Policy, please contact us via`} Email : <a className="email" href="mailto:info@duchytechservices.co.uk">info@duchytechservices.co.uk</a>
            </p>

          </div>

        </div>

      </div>


    </>
  );
}

export default Privacy;