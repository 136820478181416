import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './components/pages/Home/Home';
import Services from './components/pages/Services/Services';
import Contact from './components/pages/Contact/Contact';
import Privacy from './components/pages/Privacy/Privacy';
import Carousel from "./components/Carousel/Carousel";
import CookieConsent from 'react-cookie-consent';


function App() {

  return (
    <>
      <Header className="container" />

      <CookieConsent className="cookie-banner"
        overlay="true"
        location="top"
        buttonText="Accept"
        enableDeclineButton="true"
        declineButtonText="Reject"
        cookieName="privacycookie"
        style={{ border: "2px solid #fffd0e", background: "black", color: "black", textAlign: "left" }}
        buttonStyle={{ border: "2px solid #fffd0e", background: "#000", color: "#fffd0e", fontSize: "14px" }}
        declineButtonStyle={{ border: "2px solid #fffd0e", background: "#000", color: "#00fde", fontSize: "14px" }}
        expires={60}
      >
        <p className='cookie-statement'> This website uses cookies to enhance the user experience.  Please visit our <a href="#/Privacy" >privacy policy</a> for more information</p>
      </CookieConsent>

      <Router>

        <div>
          {/* Wrap Route elements in a Routes component */}
          <Routes>
            {/* Define routes using the Route component to render different page components at different paths */}
            {/* Define a default route that will render the Home component */}
            <Route path="/" element={<Home />} />
            <Route path="/Services" element={<Services />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/Privacy" element={<Privacy />} />
            <Route path="/Carousel" element={<Carousel />} />
            {/*<Route path="/ProjectGallery" element={<ProjectGallery />} />*/}
            {/*<Route path="/ProjPage" element={<ProjPage />} />*/}
            {/* Define a route that will have descendant routes */}
            {/* <Route path="contact/*" element={<Contact />} /> */}
          </Routes>
        </div>
        <Footer></Footer>

      </Router>

    </>
  );
}

export default App;