import React from "react";
import "./Footer.css";


function Footer() {
  return (
    <footer>
      {/* <!-- using inbuilt CSS from Bootstrap :-) --> */}
      <hr></hr>
      <nav className="nav justify-content-center tabrow nav-pills flex-md-row">
        <a className="nav-link tabtn m-1" href="#/" >Home</a>
        <a className="nav-link tabtn m-1" href="#/Services" >Services</a>
        <a className="nav-link tabtn m-1" href="#/Contact" >Contact</a>
        {/*<a className="nav-link tabtn m-1" href="#/ProjectGallery" >Project Gallery</a>*/}
      </nav>
      <hr></hr>
      <div className="links-box">
      <div className="privacy-links">
        <a className="privacy-page-link" href="#/Privacy" id="privacy-page-link">Privacy Policy</a>
      </div>

      <div className="footer-contact">
        <div>
        <h3>Contact Details</h3>
        <p>Phone : </p>
        <p className="phone">07835 818 002</p>
      </div>
      <div>
        <p> Email :</p>
        <p> <a className="email" href="mailto:info@duchytechservices.co.uk">info@duchytechservices.co.uk</a>
        </p>
      </div>
      </div>
      </div>
    </footer>
  );
}

export default Footer;
