import React from "react";
import "./Home.css";
import Carousel from "../../Carousel/Carousel";
import GoToTop from '../../GoToTop/GoToTop';

/*import techybox from "../../../assets/images/arty_techy_boxes_fabio-oyXis2kALVg-unsplash.jpg";*/



function Home() {

    return (
        <div className="home-container">
            <Carousel/>
            <div className="content-container">
                {/* <div className="logo-container container">
                    <img className="logo" alt="dts logo" src="https://raw.githubusercontent.com/benjistealth/api_weather_dashboard/2f246af731d2dbe3ffad05a14e46cf2ae99552f0/assets/images/duchy_tech_services_Logo.svg"></img>
                </div> */}
                {/* <Carousel/> */}
                <div className="blurb-container container">
                {/*<img className="techybox" alt="arty techy boxes" src={techybox}></img>*/}
                    <div className="blurb">
                        <h2>🌐 Unleash the Power of Technology with Duchy Tech Services - Your Trusted IT Partner!</h2>

                        <p>At Duchy Tech Services, we take pride in delivering top-notch technology solutions that redefine reliability and
                            cost-effectiveness for your business. With a focus on excellence, we offer a diverse range of services, ensuring
                            your technology infrastructure is not just a support system but a catalyst for growth.
                        </p>

                        <h2>💼 Cost-Effective Excellence:</h2>
                        <p>Budget constraints should never compromise quality. At Duchy Tech Services, we redefine cost-effectiveness by providing
                            cutting-edge technology solutions that align seamlessly with your budgetary considerations. Our commitment is to deliver
                            exceptional value without compromising on performance.
                        </p>
                        <h2>🔧 Comprehensive Software Expertise:</h2>
                        <p>Navigate the digital landscape confidently with our comprehensive software services. From seamless installations to intricate
                            configurations, our experts ensure that your software ecosystem is optimized for peak performance. Your business deserves
                            nothing less than the best, and that's what we deliver.
                        </p>
                        <h2>🌐 Network Infrastructure Mastery:</h2>
                        <p>Build a robust foundation for your business with our network infrastructure installation services. Our team of skilled professionals
                            is dedicated to designing and implementing scalable, secure, and efficient network solutions. We don't just connect devices;
                            we empower your connectivity.
                        </p>
                        <h2>🔗 Seamless Integration, Seamless Business:</h2>
                        <p>Integration is the key to operational efficiency. At Duchy Tech Services, we go beyond providing individual services.
                            We ensure seamless integration of technology components to create a holistic, synergistic IT environment that amplifies your business capabilities.
                        </p>
                        <h2>🛡️ Security First Approach:</h2>
                        <p>Safeguarding your digital assets is non-negotiable. Our "Security First" approach ensures that every solution we provide is fortified
                             against potential threats. Your business continuity and data integrity are our top priorities.
                        </p>
                        <h2>🌟 Empowering Your Business Future:</h2>
                        <p>Beyond the present challenges, we are committed to empowering your business for the future. Duchy Tech Services is not just an IT service provider;
                             we are your strategic partner in technological evolution. Let's journey together towards a future where your business thrives on the cutting edge.
                        </p>
                     

                    </div>
                </div>
            </div>
            <GoToTop />

        </div>
    );
}

export default Home;
