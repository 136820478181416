import React from "react";
import "./Services.css";
import GoToTop from '../../GoToTop/GoToTop';


function Services() {

  return (
    <div className="container services-container">
      <h1 className="about-title">Services</h1>
      {/* <!-- added py-5 and px-5 for vertical and horizontal padding to content --> */}
      <div className="about-content container">
        <div className="about" id="about">
          <h2>Network infrastructure - design & implementation</h2>
          <p>
            We can design your network and install the equipment with or without associated cabling to suit your business needs.
            Add to or modify an existing network to accommodate business expansion etc.
            Debug and repair issues with current network.
            Fibre, Ethernet, Wifi and point to point wireless connectivity available.
          </p>
          <h2>Why Choose Duchy Tech Services ?</h2>

          <p>Trusted expertise that Matters:
            A friendly, reliable local service when you need it.
            At Duchy Tech Services, we bring a wealth of expertise to the table.
            Our team of seasoned IT professionals is dedicated to delivering solutions tailored to your unique business needs.
            From infrastructure management to cybersecurity, we've got you covered.
          </p>
        </div>

        <h2>Comprehensive IT Services:</h2>
        <p>Emergency call out service when you have a critical system down</p>
        <p>Backup Solutions</p>
        <p>Disaster recovery</p>
        <p>Ransomware prevention</p>
        <p>Servers</p>
        <p>Websites, WordPress, Hosting</p>
        <p>Bespoke software solutions</p>
        <p>System linking - Transfer data between systems automatically</p>
        <p>Software installations / upgrades</p>
        <p>Computer hardware diagnostics and repair</p>
        <p>Training - Get your staff up to speed with software systems</p>
        <p>User management</p>
        <p>Systems stream-lining: Get the most out of your IT systems</p>
        <p>Home automation installation, upgrades and repairs</p>
        <p>Security camera systems installation, upgrades and repairs </p>
        <p>Phone systems installation, upgrades and repairs</p>
        <p>Learn how to integrate AI into your business today, reap the benefits tomorrow</p>

        <h2>Fortify Your Defenses by hardening your security</h2>
        <p> In an era of increasing cyber threats, security is paramount.
          Our state-of-the-art cybersecurity measures ensure that your sensitive data remains protected.
          Trust us to safeguard your business against evolving digital risks.
        </p>

        <h2>Drive Innovation, Fuel Growth:</h2>
        <p>   Stay ahead of the competition with our innovative solutions. Leverage the latest in technology to optimize processes,
          enhance productivity, and unlock new opportunities for growth.
          We're not just an IT service provider; we're your strategic ally in progress.
        </p>

        <h2>Client-Centric Approach:</h2>
        <p>Our commitment to client satisfaction sets us apart. We work closely with you to understand your goals and challenges,
          providing personalized solutions that align with your vision for success. Your success is our success.  With Duchy Tech Services, your systems are your own,
          we can manage your systems on site or remotely, but the customer always has control to their own systems.
        </p>

      </div>

      <GoToTop />
    </div>
  );
}

export default Services;