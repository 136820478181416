import React from "react";
import "./Header.css";


function Header() {

  return (
    <>

      <div className="header">
        <div className="title-box">
          <hr></hr>
          <a href="#/" >
          <img className="sheild" alt="sheild-logo" src="https://raw.githubusercontent.com/benjistealth/api_weather_dashboard/2a745fa617b1bd3b0cd678e39455bac0963416f4/assets/images/dts_sheild_only.svg"></img>
          </a>
          <h1 className="heading">Duchy Tech Services</h1>
          <hr></hr>
        </div>

        
        <nav className="nav justify-content-center tabrow nav-pills flex-md-row">
          <a className="nav-link tabtn m-1" href="#/" >Home</a>
          <a className="nav-link tabtn m-1" href="#/Services" >Services</a>
          <a className="nav-link tabtn m-1" href="#/Contact" >Contact</a>
          {/*<a className="nav-link tabtn m-1" href="#/ProjectGallery" >Project Gallery</a>*/}
        </nav>
      <hr></hr>

      </div>
    </>
  );
}

export default Header; 
