
import React from 'react';
import './Carousel.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/modules';
import 'swiper/css/bundle';


import slide_image_1 from '../../assets/images/reliability_w_text.png';
import slide_image_2 from '../../assets/images/futureistic_w_text_2.png';
import slide_image_3 from '../../assets/images/pcb_w_text.png';
import slide_image_4 from '../../assets/images/sunnydays_w_text_2.png';
import slide_image_5 from '../../assets/images/AI_w_text.png';


function Carousel() {
    return (


        <>
            <div className='carousel-page'>
                <div className="logo-container container">
                    <img className="logo" alt="dts logo" src="https://raw.githubusercontent.com/benjistealth/api_weather_dashboard/2f246af731d2dbe3ffad05a14e46cf2ae99552f0/assets/images/duchy_tech_services_Logo.svg"></img>
                </div>
                <div className="carousel-container">
                    <Swiper
                        autoplay={{ delay: 5000, disableOnInteraction: "true" }}
                        effect={'fade'}
                        grabCursor={true}
                        centeredSlides={true}
                        loop={true}
                        slidesPerView={'auto'}
                        effectFade={{
                            crossFade: true,
                        }}
                        pagination={{ el: '.swiper-pagination', clickable: true }}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                            clickable: true,
                        }}
                        modules={[EffectFade, Pagination, Navigation, Autoplay]}
                        className="swiper_container"
                    >
                        <SwiperSlide >
                            <img src={slide_image_1} alt="slide_image" />
                        </SwiperSlide>
                        <SwiperSlide >
                            <img src={slide_image_2} alt="slide_image" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={slide_image_3} alt="slide_image" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={slide_image_4} alt="slide_image" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={slide_image_5} alt="slide_image" />
                        </SwiperSlide>


                        <div className="slider-controller">
                            <div className="swiper-button-next slider-arrow"></div>
                            <div className="swiper-button-prev slider-arrow"></div>
                        </div>

                    </Swiper>
                </div>
            </div>

        </>

    );
}

export default Carousel;
