import React, { Component } from "react";
import emailjs from '@emailjs/browser';
import "./Contact.css";
import GoToTop from '../../GoToTop/GoToTop';

class Form extends Component {
  // Setting the component's initial state
  state = {
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
    company: "",
    message: "",
  };

  handleInputChange = event => {
    // Getting the value and name of the input which triggered the change
    const value = event.target.value;
    const name = event.target.name;

    // Updating the input's state
    this.setState({
      [name]: value
    });
  };

  handleFormSubmit = event => {
    // Preventing the default behavior of the form submit (which is to refresh the page)
    event.preventDefault();


    if (!(this.state.lastName && this.state.firstName && this.state.email && this.state.subject && this.state.message)) {
      alert("Please complete all fields!");
      return;
    }

    // Alert the user their first and last name, clear `this.state.firstName` and `this.state.lastName`, clearing the inputs
    alert(`Submitted with your message - ${this.state.firstName} ${this.state.lastName}\n ${this.state.email}\n ${this.state.subject}\n ${this.state.message} `);

    const templateParams = {
      from_name: this.state.firstName + " " + this.state.lastName,
      from_email: this.state.email,
      from_subject: this.state.subject,
      from_company: this.state.company,
      from_message: this.state.message,
    };

    emailjs.send('service_7jwotpd', 'template_u4keras', templateParams, 'SyaYQHyzHbdI8mEIa')
      .then(function (response) {
        alert("Email Sent.....😍\n\n Please note that you will receive a copy\n of the sent message at the address entered\n Don't forget to check your spam folder !");
      }, function (error) {
        alert("Sorry, an email send error has occurred\n .....Please try again later, thank you");
      });


    this.setState({
      firstName: "",
      lastName: "",
      email: "",
      subject: "",
      company: "",
      message: "",
    });
  };

  render() {
    // each input has a `value`, `name`, and `onChange` prop
    return (
      <>
        <h2 className="contact-title">Contact DTS</h2>
        <div className="container contact-page-container">
          <div className="statement-container">
            <h2>📞 Connect with us Today:</h2>
            <p>IT services in Cornwall, building technology in cornwall and the Southwest, remote and onsite work.
              Contact us today for a personalized consultation.
            </p>
            <h2>🔍 Discover the Duchy Tech Services Advantage:</h2>
            <p>Ready to transform your business through the power of technology? Discover the Duchy Tech Services advantage today.
              Contact us for a consultation, and let's embark on a journey of technological excellence together.
            </p>
          </div>

          <div className="container form-container">

            <p className="hello">
              {/*Hello {this.state.firstName} {this.state.lastName}*/}
            </p>
            <form className="form" autoComplete="off">
              <input
                value={this.state.firstName}
                name="firstName"
                onChange={this.handleInputChange}
                type="text"
                placeholder="First name"
              />
              <input
                value={this.state.lastName}
                name="lastName"
                onChange={this.handleInputChange}
                type="text"
                placeholder="Last name"
              />
              <input
                value={this.state.email}
                name="email"
                onChange={this.handleInputChange}
                type="text"
                placeholder="Email address"
              />
              <input
                value={this.state.subject}
                name="subject"
                onChange={this.handleInputChange}
                type="text"
                placeholder="Subject info"
              />
              <input
                value={this.state.company}
                name="company"
                onChange={this.handleInputChange}
                type="text"
                placeholder="Company name"
              />
              <textarea className="message"
                value={this.state.message}
                name="message"
                onChange={this.handleInputChange}
                type="text"
                placeholder="Please type your message here"
              />
              <button className="submit" onClick={this.handleFormSubmit}>Submit</button>
            </form>

          </div>

        </div>
        <div className="contact-image-container">
          <div className="contact-image" id="contact-image">

          </div>
        </div>
        <GoToTop />
      </>
    );
  }
}

export default Form;
